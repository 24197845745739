import { useEffect, useState, useMemo } from 'react';
import type { ReactElement } from 'react';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import {
  useExperienceContext,
  QubitCustomEvents,
} from '~/utilities/context/dynamic/ExperienceOptionsProvider';
import { colors } from '../../../../shared/core/styles';
import type { Size, StockLevelMessage, Product } from '~/utilities/graphql/codegen';
import { SizeType, StockLevelMessageKey } from '~/utilities/graphql/codegen';
import type { ProductSizeSelector } from '~/amplienceTypes/schemas/imported/product-size-selector-schema';
import type { ProductCountdownTimer } from '~/amplienceTypes/schemas/imported/product-countdown-timer-schema';
import { triggerQubitEvent } from '~/utilities/vendors';
import {
  checkSizeFormatted,
  parseDoubleSizes,
  parseSelectedDoubleSize,
  parseStockInformation,
} from '~/utilities/parsers';
import type { ProductOverlayDialogProps } from '../productOverlayDialog/ProductOverlayDialog';
import type { ProductOverlay } from '~/amplienceTypes/schemas/imported/product-overlay-schema';
import { ProductAddActions } from '../ProductAddActions';
import { useStaticContext } from '~/utilities/context/static/StaticContext';
import { useSizeContext } from '~/utilities/context/dynamic/SizeContext';
import { useAppContext } from '~/utilities/context/static/AppContext';
import { usePDPDynamicContext } from '~/utilities/context/dynamic/PDPDynamicContext';
import { usePDPContext } from '~/utilities/context/static/PDPContext';
import { useMediaQueryContext } from '~/utilities/context/dynamic/MediaQueryContext';
import { storageAvailable } from '~/utilities/helpers';
import {
  SIZE_TRANSFER_LENGTH,
  SIZE_TRANSFER_RANGE_IS_WAIST_LENGTH,
  SIZE_TRANSFER_SIZE,
} from '~/utilities/constants/localStorageKeys';
import { ProductShippingEstimates } from '../ProductShippingEstimates';
import { CountdownTimer } from '../../../countdownTimer/CountdownTimer';
import type { FitAnalyticsProps } from '../../../fitanalytics/Fitanalytics';
import type { ProductMeasurementsBlockProps } from '../ProductMeasurementsBlock';
import { Measurements } from '../Measurements';
import BellIcon from '../../../../shared/core/icons/BellIcon';
import NotifyBackInStockActions from '../NotifyBackInStockActions';
import { useCookieContext } from '~/utilities/context/dynamic/CookieContext';
import { useLayoutDesignContext } from '~/utilities/context/dynamic/LayoutDesignContext';
import { fetchProduct } from '~/utilities/graphql/queries';
import { useApolloContext } from '~/campaign/core/context/ApolloContext';
import { S } from './styles';
import { useConsentContext } from '~/utilities/context/dynamic/ConsentContext';

const ProductOverlayDialog = dynamic<ProductOverlayDialogProps & ProductOverlay>(
  () =>
    import('../productOverlayDialog/ProductOverlayDialog').then(mod => mod.ProductOverlayDialog),
  { ssr: false }
);

const Fitanalytics = dynamic<FitAnalyticsProps>(
  () => import('../../../fitanalytics/Fitanalytics').then(mod => mod.Fitanalytics),
  { ssr: false }
);

const ProductMeasurementsBlock = dynamic<ProductMeasurementsBlockProps>(
  () => import('../ProductMeasurementsBlock').then(mod => mod.ProductMeasurementsBlock),
  { ssr: false }
);

// TODO  Add/Remove items to/from wishlist

const shortenSize = (size: string) => {
  const matches = size.toLowerCase().match(/^x{3,}/);

  if (matches) {
    return `${matches[0].length}X${size.charAt(size.length - 1)}`;
  }

  return size;
};

interface ProductSizeSelectorProps extends ProductSizeSelector {
  productCountdownTimer?: {
    content?: ProductCountdownTimer;
    [k: string]: unknown;
  };
  isOutOfStockSizeSelected: boolean;
  setIsOutOfStockSizeSelected: (value: boolean) => void;
  setHasLocalStoreStock: (value: boolean) => void;
  setSelectedSize: (value?: Size | null) => void;
}

export const SizeSelector = ({
  sizeLabel,
  productAddActions,
  overlay,
  waistLabel,
  lengthLabel,
  productMeasurements,
  productCountdownTimer,
  isOutOfStockSizeSelected,
  setIsOutOfStockSizeSelected,
  setHasLocalStoreStock,
  setSelectedSize,
  ...props
}: ProductSizeSelectorProps): ReactElement => {
  const { country, locale, siteType } = useAppContext();
  const { client: apolloClient } = useApolloContext();
  const { product } = usePDPContext();
  const { dynamicProductData } = usePDPDynamicContext();
  const {
    configuration: { enablePdpLocalStoreStockSizeAvailability },
  } = useStaticContext();
  const { hasProductAccordions } = useLayoutDesignContext();
  const { isMobileOrTablet, isDesktop } = useMediaQueryContext();
  const { sizeCode, setSizeCode, gridValues, setGridValues } = useSizeContext();
  const { hasMarketingConsent, hasAnalyticsConsent } = useConsentContext();

  const sizeInformation = dynamicProductData.sizeInformation ?? product.sizeInformation;
  const stockInformation = dynamicProductData.stockInformation ?? product.stockInformation;
  const comingSoon = dynamicProductData.comingSoon ?? product.comingSoon;

  const sizes = useMemo(() => sizeInformation?.sizes || [], [sizeInformation]);

  const { waistSizes, lengthSizes } = parseDoubleSizes(sizes);
  const [parsedWaistSizes, setParsedWaistSizes] = useState(waistSizes);
  const [parsedLengthSizes, setParsedLengthSizes] = useState(lengthSizes);
  const [backInStockDialogOpen, setBackInStockDialogOpen] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [activatable, setActivatable] = useState(true);
  const [promptToSelectWaist, setPromptToSelectWaist] = useState(false);
  const [promptToSelectLength, setPromptToSelectLength] = useState(false);
  const [stockLevelMessage, setStockLevelMessage] = useState<StockLevelMessage>({
    key: stockInformation?.stockLevel,
    value: stockInformation?.stockMessage,
  });
  const { experienceOptions } = useExperienceContext();
  const { t } = useTranslation('pdp', { keyPrefix: 'productSizeSelector' });
  const [currentSizeVariantProduct, setCurrentSizeVariantProduct] = useState<Product>(product);

  const showProductMeasurements = useMemo(
    () =>
      product.shouldRequestSizeMeasurements &&
      productMeasurements &&
      sizeInformation?.sizeType === SizeType.NumericDouble &&
      (isMobileOrTablet ||
        (isDesktop &&
          hasProductAccordions &&
          (productMeasurements as ProductMeasurementsBlockProps)?.content?.visibility?.desktop ===
            true)),
    [
      product.shouldRequestSizeMeasurements,
      productMeasurements,
      sizeInformation?.sizeType,
      isMobileOrTablet,
      isDesktop,
      hasProductAccordions,
    ]
  );

  const { cocoCookie, oneTrustCookie } = useCookieContext();
  const cookieConsentExists = !!cocoCookie || !!oneTrustCookie;

  useEffect(() => {
    setParsedWaistSizes(waistSizes);
    setParsedLengthSizes(lengthSizes);
    // Including waistSizes and lengthSizes to the dependencies array, will lead to infinite update,
    // because these variables changes on every render. Instead sizeInformation is used as a dependency.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizeInformation]);

  useEffect(() => {
    if (!enablePdpLocalStoreStockSizeAvailability || !sizeCode) {
      return;
    }

    const fetchData = async () => {
      try {
        const { product } = await fetchProduct({
          siteType,
          code: sizeCode,
          locale,
          apolloClient,
        });

        setCurrentSizeVariantProduct(product);
        setHasLocalStoreStock(product.hasLocalStoreStock ?? false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Fetch Product Error :: ', error);
      }
    };

    fetchData();
  }, [
    enablePdpLocalStoreStockSizeAvailability,
    sizeCode,
    setHasLocalStoreStock,
    locale,
    siteType,
    apolloClient,
  ]);

  useEffect(() => {
    if (sizeInformation?.sizes && sizeCode) {
      if (sizeInformation?.sizeType === SizeType.NumericDouble) {
        if (gridValues.gridValue1 && gridValues.gridValue2) {
          const stock = parseStockInformation(
            gridValues.gridValue1,
            gridValues.gridValue2,
            stockInformation
          );

          setStockLevelMessage(stock);
        } else if (experienceOptions?.tk22_1?.experienceId) {
          setStockLevelMessage({
            key: stockInformation?.stockLevel,
            value: stockInformation?.stockMessage,
          });
        }
      } else {
        const size = sizeInformation?.sizes.find(
          s => s?.code?.toLowerCase() === sizeCode.toLocaleLowerCase()
        );

        if (size) {
          const thresholdsLeft = stockInformation?.stockMapping?.find(
            stockMapping => stockMapping?.key === size.gridValue1
          )?.value?.thresholdsLeft;
          const stockLevel = thresholdsLeft ? thresholdsLeft[0]?.stockLevel : null;
          const stockQuantity = thresholdsLeft ? thresholdsLeft[0]?.stockQuantity || 0 : 0;
          const stockMessage = stockInformation?.stockLevelMessages?.find(
            message => message?.key === stockLevel
          )?.value;

          setStockLevelMessage({
            key: stockLevel,
            value: stockMessage?.replace('%s', stockQuantity.toString()),
          });
        }
      }
    } else {
      setStockLevelMessage({
        key: stockInformation?.stockLevel,
        value: stockInformation?.stockMessage,
      });
    }
  }, [
    gridValues,
    sizeCode,
    sizeInformation,
    stockInformation,
    experienceOptions?.tk22_1?.experienceId,
  ]);

  useEffect(() => {
    const checkForOutOfStockSizeSelector = () => {
      const allSizes = [
        ...Object.values(parsedWaistSizes || {}),
        ...Object.values(parsedLengthSizes || {}),
        ...(Array.isArray(sizes) ? sizes : [sizes]),
      ];
      const isOutOfStockSizePresent = allSizes.some(size => !size || !size.purchasable);

      if (isOutOfStockSizePresent) {
        document.dispatchEvent(
          new CustomEvent(QubitCustomEvents.TK22_1_EXPERIENCE_OPTIONS_REQUEST)
        );
      }
    };

    document.addEventListener(
      QubitCustomEvents.TK22_1_IS_OUT_OF_STOCK_SIZE_DETECTED,
      checkForOutOfStockSizeSelector
    );

    checkForOutOfStockSizeSelector();

    return () =>
      document.removeEventListener(
        QubitCustomEvents.TK22_1_IS_OUT_OF_STOCK_SIZE_DETECTED,
        checkForOutOfStockSizeSelector
      );
  }, [parsedWaistSizes, parsedLengthSizes, sizes]);

  useEffect(() => {
    if (
      !experienceOptions?.tk22_1?.experienceId ||
      sizeInformation?.sizeType !== SizeType.NumericDouble
    ) {
      return;
    }

    const { gridValue1, gridValue2 } = gridValues;

    const handleSizeState = (
      parsedSizes: Record<string, { purchasable?: boolean | undefined }>,
      gridValue: string,
      setIsOutOfStockSizeSelected: (value: boolean) => void
    ) => {
      const isSizeAvailable = Object.entries(parsedSizes).reduce((acc, [sizeValue, sizeInfo]) => {
        if (sizeValue !== gridValue) {
          return acc;
        }

        return !!sizeInfo.purchasable;
      }, false);

      setIsOutOfStockSizeSelected(!isSizeAvailable);
    };

    if (gridValue1) {
      handleSizeState(parsedWaistSizes, gridValue1, setIsOutOfStockSizeSelected);
    }

    if (gridValue2) {
      handleSizeState(parsedLengthSizes, gridValue2, setIsOutOfStockSizeSelected);
    }
  }, [
    gridValues,
    setGridValues,
    parsedLengthSizes,
    parsedWaistSizes,
    experienceOptions?.tk22_1?.experienceId,
    sizeInformation?.sizeType,
    setIsOutOfStockSizeSelected,
  ]);

  const { isSizeFormatted, sizeButtonStyle } = checkSizeFormatted(sizes, country);

  const selectSize = (size?: Size | null) => {
    setGridValues(prev => ({ ...prev, gridValue1: size?.value || '' }));
    setSelectedSize(size);

    if (!size?.purchasable && size?.willBeAvailable) {
      if (experienceOptions?.tk22_1?.experienceId && size?.value) {
        setActivatable(true);
        setIsOutOfStockSizeSelected(true);
      } else {
        document.dispatchEvent(
          new CustomEvent(QubitCustomEvents.TK20_EXPERIENCE_OPTIONS_RESPONSE, {
            detail: { experienceName: 'tk20' },
          })
        );
        setBackInStockDialogOpen(true);
        setActivatable(false);
      }
    } else {
      setSizeCode(size?.code || '');

      document.dispatchEvent(new CustomEvent(QubitCustomEvents.TK20_EXPERIENCE_OPTIONS_REQUEST));

      if (experienceOptions?.tk22_1?.experienceId) {
        setIsOutOfStockSizeSelected(false);
      }
    }
  };

  const selectDoubleSize = (sizeCodeValue: string, size: Size, isWaist: boolean) => {
    setSelectedSize(size);

    const selectedGridValues = {
      gridValue1: isWaist ? sizeCodeValue : gridValues.gridValue1 || '',
      gridValue2: isWaist ? gridValues.gridValue2 || '' : sizeCodeValue,
    };

    const rearrangeSizes = () => {
      const parsedSizes = parseSelectedDoubleSize(sizeCodeValue, sizes, isWaist);
      const selectedSize = sizes.find(
        size =>
          size?.value === (selectedGridValues.gridValue1 || '') + selectedGridValues.gridValue2
      );

      setSizeCode(prev => selectedSize?.code || prev);

      if (isWaist) {
        setParsedLengthSizes(parsedSizes);
      } else {
        setParsedWaistSizes(parsedSizes);
      }
    };

    setGridValues(selectedGridValues);

    if (experienceOptions?.tk22_1?.experienceId && isWaist) {
      setPromptToSelectWaist(false);
    } else {
      setPromptToSelectLength(false);
    }

    if (!size?.purchasable && size?.willBeAvailable) {
      if (experienceOptions?.tk22_1?.experienceId) {
        setActivatable(true);
        rearrangeSizes();
      } else {
        document.dispatchEvent(
          new CustomEvent(QubitCustomEvents.TK20_EXPERIENCE_OPTIONS_RESPONSE, {
            detail: { experienceName: 'tk20' },
          })
        );
        setBackInStockDialogOpen(true);
        setActivatable(false);
      }
    } else {
      rearrangeSizes();

      if (selectedGridValues.gridValue1 && selectedGridValues.gridValue2) {
        document.dispatchEvent(new CustomEvent(QubitCustomEvents.TK20_EXPERIENCE_OPTIONS_REQUEST));
      }
    }
  };

  const createSizeText = (sizeText: string, isWaist: boolean) => {
    const localeValue = sizeText.split(' ')[0];
    const waistValue = sizeText.split(' ')[1];
    const lengthValue = sizeText.split(' ')[2];

    if (localeValue && waistValue && lengthValue) {
      return isWaist
        ? `${localeValue}${waistValue.replace('W', ' ')}`
        : `${localeValue}${lengthValue.replace('L', ' ')}`;
    }

    return '';
  };

  useEffect(() => {
    const isLocalStorageAvailable = storageAvailable('localStorage');
    const productSizeValue = (product.code as string).split('-')[3]?.toUpperCase();
    const isProductVariant = !!product.baseProduct && !!productSizeValue;
    const isDoubleSize = sizeInformation?.sizeType === SizeType.NumericDouble;

    const storageWaistSize: string[] = JSON.parse(
      isLocalStorageAvailable ? localStorage.getItem(SIZE_TRANSFER_SIZE) || '[]' : '[]'
    );
    const storageLengthSize: string[] = JSON.parse(
      isLocalStorageAvailable ? localStorage.getItem(SIZE_TRANSFER_LENGTH) || '[]' : '[]'
    );

    const isLocalDoubleSize: boolean = JSON.parse(
      isLocalStorageAvailable
        ? localStorage.getItem(SIZE_TRANSFER_RANGE_IS_WAIST_LENGTH) || 'false'
        : 'false'
    );

    const singleValueStorageSize = storageWaistSize.length === 1;
    const singleValueStorageLength = storageLengthSize.length === 1;

    const isLocalVariant = isDoubleSize
      ? isLocalDoubleSize
      : singleValueStorageSize && !isLocalDoubleSize;

    let gridValue1 = '';
    let gridValue2 = '';
    let shouldOpenDialog = false;
    let selectedSizeProductCode = '';

    if (isProductVariant || isLocalVariant) {
      // Getting the grid value from the local storage value set from plp to make "24 (US 00)" to "24"
      const storageGridValue1 =
        isLocalVariant && singleValueStorageSize
          ? storageWaistSize[0].toUpperCase().split(' ')[0]
          : '';
      const storageGridValue2 =
        isLocalVariant && singleValueStorageLength
          ? storageLengthSize[0].toUpperCase().split(' ')[0]
          : '';

      if (isDoubleSize) {
        gridValue1 = isProductVariant ? productSizeValue.slice(0, 2) : storageGridValue1;
        gridValue2 = isProductVariant ? productSizeValue.slice(2, 4) : storageGridValue2;

        if (gridValue1 && gridValue2) {
          shouldOpenDialog = !sizeInformation?.sizes?.find(
            size =>
              size?.gridValue1?.toUpperCase() === gridValue1 &&
              size?.gridValue2?.toUpperCase() === gridValue2
          )?.purchasable;
        }
      } else {
        const selectedSize = sizeInformation?.sizes?.find(
          size => size?.code?.toUpperCase() === product.code.toUpperCase()
        );

        gridValue1 = isProductVariant ? selectedSize?.gridValue1 ?? '' : storageGridValue1;
        gridValue2 = '';
        shouldOpenDialog = !sizeInformation?.sizes?.find(
          size => size?.gridValue1?.toUpperCase() === gridValue1
        )?.purchasable;
      }

      if (isProductVariant) {
        selectedSizeProductCode = product.code;
      } else if (isLocalVariant) {
        if (isDoubleSize) {
          selectedSizeProductCode =
            gridValue1 && gridValue2 ? `${product.code}-${gridValue1}${gridValue2}` : '';
        } else {
          selectedSizeProductCode =
            sizeInformation?.sizes?.find(size => size?.gridValue1 === gridValue1)?.code ?? '';
        }
      }

      setBackInStockDialogOpen(shouldOpenDialog);
      setActivatable(!shouldOpenDialog);
      setGridValues({ gridValue1, gridValue2 });

      if (!shouldOpenDialog) {
        setSizeCode(selectedSizeProductCode);
      }
    } else {
      setGridValues({ gridValue1: '', gridValue2: '' });
      setBackInStockDialogOpen(false);
    }

    if (product.oneSize) {
      const oneSize = sizes?.at(0);
      const shouldNotify = Boolean(oneSize?.notPurchasableDueToStock && oneSize?.willBeAvailable);

      setSizeCode(oneSize?.code || 'PC');
      setBackInStockDialogOpen(shouldNotify);
    }

    setInitialized(true);
  }, [
    product.baseProduct,
    product.code,
    product.oneSize,
    sizes,
    setGridValues,
    setSizeCode,
    sizeInformation?.sizeType,
    sizeInformation?.sizes,
  ]);

  useEffect(() => {
    if (initialized && Object.keys(dynamicProductData).length > 0) {
      const productEventData = {
        ...product,
        ...dynamicProductData,
        baseProduct: sizeCode ? product.baseProduct || product.code : product.baseProduct,
        code: sizeCode || product.code,
      };

      triggerQubitEvent('ecProduct', productEventData, hasMarketingConsent && hasAnalyticsConsent);
    }
  }, [
    sizeCode,
    product,
    initialized,
    dynamicProductData,
    hasMarketingConsent,
    hasAnalyticsConsent,
  ]);

  const renderSizes = () => {
    if (!comingSoon) {
      if (sizeInformation?.sizeType === SizeType.NumericDouble) {
        return (
          <>
            <S.SizeWrapper>
              <S.SizeType
                component="h3"
                variant="h3"
                color={colors.NERO_GREY}
                testId="size-width-label"
                data-cs-capture=""
              >
                {waistLabel || t('waistLabel')}
              </S.SizeType>
              <S.Sizes data-testid="width-size-selector">
                {Object.entries(parsedWaistSizes).map(([sizeValue, sizeInfo], i) => (
                  <S.Size
                    key={i}
                    $disabled={!sizeInfo.purchasable}
                    $selected={
                      activatable &&
                      sizeValue.toUpperCase() === gridValues.gridValue1 &&
                      (sizeInfo.purchasable || !sizeInfo.willBeAvailable)
                    }
                    onClick={() => selectDoubleSize(sizeValue, sizeInfo, true)}
                    data-testid={`width-size${!sizeInfo.purchasable ? '-disabled' : ''}${
                      activatable && sizeValue.toUpperCase() === gridValues.gridValue1
                        ? '-active'
                        : ''
                    }`}
                    $btnStyle={sizeButtonStyle}
                    data-cs-capture=""
                  >
                    {experienceOptions?.tk22_1?.experienceId && !sizeInfo.purchasable && (
                      <S.BellIconWrapper>
                        <BellIcon />
                      </S.BellIconWrapper>
                    )}
                    {sizeValue}
                    {isSizeFormatted && sizeInfo.purchasable && (
                      <S.SizeText variant="body" component="p" data-cs-capture="">
                        {createSizeText(
                          sizeInfo?.formattedValue?.replace(/(^.*\(|\).*$)/g, '') || '',
                          true
                        )}
                      </S.SizeText>
                    )}
                  </S.Size>
                ))}
              </S.Sizes>
            </S.SizeWrapper>
            <S.SizeWrapper>
              <S.SizeType
                component="h3"
                variant="h3"
                color={colors.NERO_GREY}
                testId="size-length-label"
                data-cs-capture=""
              >
                {lengthLabel || t('lengthLabel')}
              </S.SizeType>
              <S.Sizes data-testid="length-size-selector">
                {Object.entries(parsedLengthSizes).map(([sizeValue, sizeInfo], i) => (
                  <S.Size
                    key={i}
                    $disabled={!sizeInfo.purchasable}
                    $selected={
                      activatable &&
                      sizeValue.toUpperCase() === gridValues.gridValue2 &&
                      (sizeInfo.purchasable || !sizeInfo.willBeAvailable)
                    }
                    onClick={() => selectDoubleSize(sizeValue, sizeInfo, false)}
                    data-testid={`length-size${!sizeInfo.purchasable ? '-disabled' : ''}${
                      activatable && sizeValue.toUpperCase() === gridValues.gridValue2
                        ? '-active'
                        : ''
                    }`}
                    $btnStyle={sizeButtonStyle}
                    data-cs-capture=""
                  >
                    {experienceOptions?.tk22_1?.experienceId && !sizeInfo.purchasable && (
                      <S.BellIconWrapper>
                        <BellIcon />
                      </S.BellIconWrapper>
                    )}
                    {sizeValue}
                  </S.Size>
                ))}
              </S.Sizes>
            </S.SizeWrapper>
          </>
        );
      }

      return (
        <S.SizeWrapper>
          <S.SizeType
            component="h3"
            variant="h3"
            color={colors.NERO_GREY}
            testId="size-width-label"
            data-cs-capture=""
          >
            {sizeLabel || t('sizeLabel')}
          </S.SizeType>
          <S.Sizes data-testid="width-size-selector">
            {sizes.map((size, i) => (
              <S.Size
                key={i}
                $disabled={!size?.purchasable}
                $selected={activatable && size?.gridValue1?.toUpperCase() === gridValues.gridValue1}
                onClick={() => selectSize(size)}
                data-testid={`width-size${!size?.purchasable ? '-disabled' : ''}${
                  activatable && size?.gridValue1?.toUpperCase() === gridValues.gridValue1
                    ? '-active'
                    : ''
                }`}
                $btnStyle={sizeButtonStyle}
                data-cs-capture=""
              >
                {shortenSize(size?.gridValue1 ?? '')}
                {experienceOptions?.tk22_1?.experienceId && !size?.purchasable && (
                  <S.BellIconWrapper>
                    <BellIcon />
                  </S.BellIconWrapper>
                )}
                {isSizeFormatted && size?.purchasable && (
                  <S.SizeText variant="body" component="p" data-cs-capture="">
                    {size?.formattedValue?.replace(/(^.*\(|\).*$)/g, '')}
                  </S.SizeText>
                )}
              </S.Size>
            ))}
          </S.Sizes>
        </S.SizeWrapper>
      );
    }

    return <></>;
  };

  return (
    <S.Wrapper>
      {!product.isGiftCard && !product.oneSize && (
        <>
          {showProductMeasurements && <ProductMeasurementsBlock {...productMeasurements} />}
          {renderSizes()}
          {experienceOptions?.tk20?.translations?.title && (
            <Measurements title={experienceOptions?.tk20?.translations?.title} {...props} />
          )}
          <Fitanalytics sizeInformation={sizeInformation} />
        </>
      )}
      {!isOutOfStockSizeSelected && stockLevelMessage.key && !comingSoon && (
        <>
          <S.Stock>
            <S.StockAvailability
              component="p"
              variant="title"
              color={
                stockLevelMessage.key === StockLevelMessageKey.Plenty
                  ? colors.FREE_RETURN_FEE_GREEN
                  : colors.STOCK_BROWN
              }
              testId="stock-level-notification"
              data-cs-capture="true"
            >
              {stockLevelMessage.value}
            </S.StockAvailability>
          </S.Stock>
          {stockInformation?.stockQuantity !== 0 && productCountdownTimer?.content && (
            <CountdownTimer
              {...productCountdownTimer.content}
              salesDiscountsEndDate={product?.salesDiscountsEndDate}
            />
          )}
        </>
      )}
      {!isOutOfStockSizeSelected && <ProductShippingEstimates />}
      {!isOutOfStockSizeSelected && productAddActions?.content && (
        <ProductAddActions
          sizeCode={sizeCode}
          gridValues={gridValues}
          product={currentSizeVariantProduct}
          setDlgOpen={setBackInStockDialogOpen}
          icon="shoppingBag"
          trackFitAnalyticsSizes
          algoliaAddToCartEventName="PDP_addToBasket_convertedObjectIDs"
          algoliaAddToCartAfterSearchEventName="PDP_addToBasket_convertedObjectIDsAfterSearch"
          {...productAddActions.content}
        />
      )}
      {isOutOfStockSizeSelected && (
        <NotifyBackInStockActions
          gridValues={gridValues}
          sizeType={sizeInformation?.sizeType || ''}
          setPromptToSelectWaist={setPromptToSelectWaist}
          setPromptToSelectLength={setPromptToSelectLength}
          promptToSelectWaist={promptToSelectWaist}
          promptToSelectLength={promptToSelectLength}
        />
      )}

      {!product.isGiftCard && backInStockDialogOpen && overlay && cookieConsentExists && (
        <ProductOverlayDialog
          onClose={() => {
            setBackInStockDialogOpen(false);

            if (!activatable) {
              setGridValues({ gridValue1: '', gridValue2: '' });
              setSizeCode('');
            }

            setActivatable(true);
          }}
          waistLabel={waistLabel || t('waistLabel')}
          lengthLabel={lengthLabel || t('lengthLabel')}
          sizeLabel={sizeLabel || t('sizeLabel')}
          product={{ ...product, ...dynamicProductData } as Product}
          {...overlay.content}
        />
      )}
    </S.Wrapper>
  );
};
